'use strict';

import React, { Component } from 'react';
import axios from 'axios';
import Layout from '../compontents/layout';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import { Redirect } from 'react-router-dom';



export default class Edit extends Component {


  constructor(props) {
    super(props);

    this.state = {
      editor_type: "classic",
      editor1_html: "",
      editor2_html: "",
      title: "",
      param1: "",
      param2: "",
      param3: "",
      param4: "",
      param5: "",
      param6: "",
      param7: "",
	  param8: "",
	  param9: "",
	  param10: "",
      editor1_value: "",
      editor2_value: "",
	  editor3_value: "",
      editor4_value: "",
      status: false,
      image1_upload: false,
      image1_error: "",
      select1_list: [],
      select2_list: [],
	  select3_list: [],
      checkbox1_value: false,
	  error : ""
    };

    this.onChangeEditor1 = this.onChangeEditor1.bind(this);
    this.onChangeEditor2 = this.onChangeEditor2.bind(this);
	this.onChangeEditor3 = this.onChangeEditor3.bind(this);
    this.onChangeEditor4 = this.onChangeEditor4.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeCheckbox1 = this.onChangeCheckbox1.bind(this);
    this.onChangeImage1 = this.onChangeImage1.bind(this)
    this.fileUpload = this.fileUpload.bind(this)
  }

  onChangeCheckbox1(event) {
    if (this.state.checkbox1_value == "on" || this.state.checkbox1_value == true) this.setState({ checkbox1_value: false });
    else this.setState({ checkbox1_value: true });
  }


  onChangeEditor1(editor1_value) {
    this.setState({ editor1_value });
  }


  onChangeEditor2(editor2_value) {
    this.setState({ editor2_value });
  }
  
  
  onChangeEditor3(editor3_value) {
    this.setState({ editor3_value });
  }
  
  onChangeEditor4(editor4_value) {
    this.setState({ editor4_value });
  }



  onChangeImage1(e) {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
      return;

    this.setState({ image1_name: e.target.files[0].name, image1_upload: false });
    this.createImage(files[0]);
  }

  componentWillReceiveProps(nextProps) {
    this.refresh(nextProps);
  }

  refresh(props) {
    this.getData(props.controller, this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.getData(this.props.controller, this.props.match.params.id);
    }
  }

  createImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        image1_value: e.target.result
      })
      this.fileUpload();
    };

    reader.readAsDataURL(file);
  }


  fileUpload() {
    const url = 'https://api.typuje.pl/api/admin/file';
    const formData = { file: this.state.image1_value, name: this.state.image1_name, folder: this.state.image1_folder }
    axios.post(url, formData)
      .then(response => {
        console.log(response);
        console.log(response.data);
        this.setState({
          image1_name: response.data.name,
          image1_error: "",
          image1_upload: true
        })
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          this.setState({
            image1_name: "",
            image1_error: error.response.data.message
          });
        }
      })
  }

  changeEditor() {
    if (this.state.editor_type == "classic") {
      this.setState({
        editor_type: "html",
        editor1_html: this.state.editor1_value.toString('html'),
        editor2_html: this.state.editor2_value.toString('html'),
		editor3_html: this.state.editor3_value.toString('html'),
        editor4_html: this.state.editor4_value.toString('html'),
        editor1_value: "",
        editor2_value: "",
		editor3_value: "",
        editor4_value: ""
      });
    }
    else {
      this.setState({
        editor_type: "classic",
        editor1_value: this.state.editor1_html,
        editor2_value: this.state.editor2_html,
		editor3_value: this.state.editor3_html,
        editor4_value: this.state.editor4_html,
        editor3_html: "",
        editor4_html: "",
		editor1_html: "",
        editor2_html: ""
      });
    }
  }


  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }


  componentDidMount() {
    this.setState({ id: this.props.match.params.id });
    this.getData(this.props.controller, this.props.match.params.id);
  }

  getData(controller, id) {
    if (this.props.type == "edit") var url = 'https://api.typuje.pl/api/admin/' + controller + '/' + id;
    else var url = 'https://api.typuje.pl/api/admin/' + controller + '/load';



    axios.get(url,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(results => {

        let result = results.data;
        console.log(result);
        this.setState({
          title: result.title,
          param1: result.param1,
          param2: result.param2,
          param3: result.param3,
          param4: result.param4,
          param5: result.param5,
          param6: result.param6,
          param7: result.param7,
		  param8: result.param8,
		  param9: result.param9,
		  param10: result.param10,
          image1_upload: true
        });


        if (undefined !== result.editor1) this.setState({ editor1: result.editor1 }); else this.setState({ editor1: null });
        if (undefined !== result.editor2) this.setState({ editor2: result.editor2 }); else this.setState({ editor2: null });
        if (undefined !== result.editor3) this.setState({ editor3: result.editor3 }); else this.setState({ editor3: null });
        if (undefined !== result.editor4) this.setState({ editor4: result.editor4 }); else this.setState({ editor4: null });
		if (undefined !== result.image1) this.setState({ image1: result.image1 }); else this.setState({ image1: null });
        if (undefined !== result.select1) this.setState({ select1: result.select1 }); else this.setState({ select1: null });
        if (undefined !== result.select2) this.setState({ select2: result.select2 }); else this.setState({ select2: null });
        if (undefined !== result.select3) this.setState({ select3: result.select3 }); else this.setState({ select3: null });
		if (undefined !== result.checkbox1) this.setState({ checkbox1: result.checkbox1 }); else this.setState({ checkbox1: null });
        if (undefined !== result.image1_folder) this.setState({ image1_folder: result.image1_folder });


        if (result.data !== undefined) {
          if (undefined !== result.data.param1) this.setState({ param1_value: result.data.param1 });
          if (undefined !== result.data.param2) this.setState({ param2_value: result.data.param2 });
          if (undefined !== result.data.param3) this.setState({ param3_value: result.data.param3 });
          if (undefined !== result.data.param4) this.setState({ param4_value: result.data.param4 });
          if (undefined !== result.data.param5) this.setState({ param5_value: result.data.param5 });
          if (undefined !== result.data.param6) this.setState({ param6_value: result.data.param6 });
          if (undefined !== result.data.param7) this.setState({ param7_value: result.data.param7 });
		  if (undefined !== result.data.param8) this.setState({ param8_value: result.data.param8 });
		  if (undefined !== result.data.param9) this.setState({ param9_value: result.data.param9 });
		  if (undefined !== result.data.param10) this.setState({ param10_value: result.data.param10 });
          if (undefined !== result.data.select1) this.setState({ select1_value: result.data.select1 });
          if (undefined !== result.data.select2) this.setState({ select2_value: result.data.select2 });
          if (undefined !== result.data.select3) this.setState({ select3_value: result.data.select3 });
          if (undefined !== result.data.editor1) this.setState({ editor1_value: result.data.editor1 });
          if (undefined !== result.data.editor2) this.setState({ editor2_value: result.data.editor2 });
		  if (undefined !== result.data.editor3) this.setState({ editor3_value: result.data.editor3 });
          if (undefined !== result.data.editor4) this.setState({ editor4_value: result.data.editor4 });
		  if (undefined !== result.data.image1) this.setState({ image1_name: result.data.image1 });
          if (undefined !== result.data.checkbox1 && result.data.checkbox1 == 1) this.setState({ checkbox1_value: "on" });
        }

		
        if (undefined !== result.select1_list && result.select1_list.length) this.setState({ select1_list: result.select1_list });
        if (undefined !== result.select2_list && result.select2_list.length) this.setState({ select2_list: result.select2_list });
        if (undefined !== result.select3_list && result.select3_list.length) this.setState({ select3_list: result.select3_list });
     
	 })
  }


  saveData() {
    if (this.state.editor_type == "classic") {
      var e1_html = this.state.editor1_value.toString('html');
      var e2_html = this.state.editor2_value.toString('html');
	  var e3_html = this.state.editor3_value.toString('html');
      var e4_html = this.state.editor4_value.toString('html');
    }
    else {
      var e1_html = this.state.editor1_html;
      var e2_html = this.state.editor2_html;
	  var e3_html = this.state.editor3_html;
      var e4_html = this.state.editor4_html;
    }
	
	e1_html=e1_html.replaceAll('<div','<p');
	e1_html=e1_html.replaceAll('<\/div>','</p>');
	e2_html=e2_html.replaceAll('<div','<p');
	e2_html=e2_html.replaceAll('<\/div>','</p>');
	e3_html=e3_html.replaceAll('<div','<p');
	e3_html=e3_html.replaceAll('<\/div>','</p>');
	e4_html=e4_html.replaceAll('<div','<p');
	e4_html=e4_html.replaceAll('<\/div>','</p>');
	

    if (this.props.type == "edit") var url = 'https://api.typuje.pl/api/admin/' + this.props.controller;
    else var url = 'https://api.typuje.pl/api/admin/' + this.props.controller + '/add';
    axios.post(url,
      {
        param1: this.state.param1_value, param2: this.state.param2_value, param3: this.state.param3_value, param4: this.state.param4_value,
        param5: this.state.param5_value, param6: this.state.param6_value, param7: this.state.param7_value, param8: this.state.param8_value, param9: this.state.param9_value, param10: this.state.param10_value, editor1: e1_html, editor2: e2_html, editor3: e3_html, editor4: e4_html, image1: this.state.image1_name,
        select1: this.state.select1_value, select2: this.state.select2_value, select3: this.state.select3_value, checkbox1: this.state.checkbox1_value
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(results => {
        this.setState({ status: true });
        let result = results.data;
        console.log(result);
      })
	  .catch((error) => {
        if (error.response) {
          console.log(error.response.data); 
          this.setState({
            error : error.response.data.message
          }); 
          } 
      })
  }






  render() {

    const Select1 = this.state.select1_list.map((x, index) => {
      return (
        <option value={x.id}>{x.name}</option>
      )
    });

    const Select2 = this.state.select2_list.map((x, index) => {
      return (
        <option value={x.id}>{x.name}</option>
      )
    });

	const Select3 = this.state.select3_list.map((x, index) => {
      return (
        <option value={x.id}>{x.name}</option>
      )
    });

    return (
      <Layout>
        {this.state.status ? <Redirect to={"/" + this.props.controller + "/all"} /> : ""}
        <div className="main-panel">

          <div className="content-wrapper">
            <div className="row page-title-header">
              <div className="col-12">
                <div className="page-header">
                </div>
              </div>
              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">{this.props.type == 'edit' ? "Edytujesz" : "Dodajesz"} {this.state.title}</h4>
                    <form className="form-sample">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">{this.state.param2}</label>
                            <div className="col-sm-9">
                              <input type="text" onChange={this.onChange} name="param2_value" className="form-control" value={this.state.param2_value} />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">{this.state.param3}</label>
                            <div className="col-sm-9">
                              <input type="text" onChange={this.onChange} name="param3_value" className="form-control" value={this.state.param3_value} />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group row">
                            {this.state.param4 ? <label className="col-sm-3 col-form-label">{this.state.param4}</label> : ""}
                            <div className="col-sm-9">
                              {this.state.param4 ? <input type="text" onChange={this.onChange} name="param4_value" className="form-control" value={this.state.param4_value} /> : ""}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group row">
                            {this.state.param5 ? <label className="col-sm-3 col-form-label">{this.state.param5}</label> : ""}
                            <div className="col-sm-9">
                              {this.state.param5 ? <input type="text" onChange={this.onChange} name="param5_value" className="form-control" value={this.state.param5_value} /> : ""}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group row">
                            {this.state.param6 ? <label className="col-sm-3 col-form-label">{this.state.param6}</label> : ""}
                            <div className="col-sm-9">
                              {this.state.param6 ? <input type="text" onChange={this.onChange} name="param6_value" className="form-control" value={this.state.param6_value} /> : ""}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group row">
                            {this.state.param7 ? <label className="col-sm-3 col-form-label">{this.state.param7}</label> : ""}
                            <div className="col-sm-9">
                              {this.state.param7 ? <textarea type="text" onChange={this.onChange} name="param7_value" className="form-control" value={this.state.param7_value} /> : ""}
                            </div>
                          </div>
                        </div>
						
						<div className="col-md-12">
                          <div className="form-group row">
                            {this.state.param8 ? <label className="col-sm-3 col-form-label">{this.state.param8}</label> : ""}
                            <div className="col-sm-9">
                              {this.state.param8 ? <textarea type="text" onChange={this.onChange} name="param8_value" className="form-control" value={this.state.param8_value} /> : ""}
                            </div>
                          </div>
                        </div>
						
									<div className="col-md-12">
                          <div className="form-group row">
                            {this.state.param9 ? <label className="col-sm-3 col-form-label">{this.state.param9}</label> : ""}
                            <div className="col-sm-9">
                              {this.state.param9 ? <textarea type="text" onChange={this.onChange} name="param9_value" className="form-control" value={this.state.param9_value} /> : ""}
                            </div>
                          </div>
                        </div>
						
										<div className="col-md-12">
                          <div className="form-group row">
                            {this.state.param10 ? <label className="col-sm-3 col-form-label">{this.state.param10}</label> : ""}
                            <div className="col-sm-9">
                              {this.state.param10 ? <textarea type="text" onChange={this.onChange} name="param10_value" className="form-control" value={this.state.param10_value} /> : ""}
                            </div>
                          </div>
                        </div>

              
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">{this.state.editor1}</label>
                            <div className="col-sm-9" style={{ textAlign: "left" }}>
                              {this.state.editor1 && this.state.editor_type == "classic" ? <SunEditor setContents={this.state.editor1_value} showToolbar={true} enableToolbar={true} height="300px" setOptions={{
                                font: [
                                  'Arial',
                                  'Helvetica',
                                  'Times New Roman',
                                  'Verdana',
                                  'Courier New',
                                ],
                                buttonList: [
                                  ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                  ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                  ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print']
                                ]
                              }}
                                onChange={this.onChangeEditor1}
                              /> : ""}
                              {this.state.editor1 && this.state.editor_type == "html" ? <textarea type="text" style={{ minHeight: "300px" }} onChange={this.onChange} name="editor1_html" className="form-control" value={this.state.editor1_html} /> : ""}
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12">
                          <div className="form-group row">
                            {this.state.editor2 && this.state.editor_type == "classic" ? <label className="col-sm-3 col-form-label">{this.state.editor2}</label> : ""}
                            <div className="col-sm-9" style={{ textAlign: "left" }}>
                              {this.state.editor2 ? <SunEditor setContents={this.state.editor2_value} showToolbar={true} enableToolbar={true} height="300px" setOptions={{
                                font: [
                                  'Arial',
                                  'Helvetica',
                                  'Times New Roman',
                                  'Verdana',
                                  'Courier New',
                                ],
                                buttonList: [
                                  ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                  ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                  ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print']
                                ]
                              }}
                                onChange={this.onChangeEditor2}
                              /> : ""}
                              {this.state.editor2 && this.state.editor_type == "html" ? <textarea type="text" style={{ minHeight: "300px" }} onChange={this.onChange} name="editor2_html" className="form-control" value={this.state.editor2_html} /> : ""}
                            </div>
                          </div>
                        </div>
						
							 <div className="col-md-12">
                          <div className="form-group row">
                            {this.state.editor3 && this.state.editor_type == "classic" ? <label className="col-sm-3 col-form-label">{this.state.editor3}</label> : ""}
                            <div className="col-sm-9" style={{ textAlign: "left" }}>
                              {this.state.editor3 ? <SunEditor setContents={this.state.editor3_value} showToolbar={true} height="300px" enableToolbar={true} setOptions={{
                                font: [
                                  'Arial',
                                  'Helvetica',
                                  'Times New Roman',
                                  'Verdana',
                                  'Courier New',
                                ],
                                buttonList: [
                                  ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                  ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                  ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print']
                                ]
                              }}
                                onChange={this.onChangeEditor3}
                              /> : ""}
                              {this.state.editor3 && this.state.editor_type == "html" ? <textarea type="text" style={{ minHeight: "300px" }} onChange={this.onChange} name="editor3_html" className="form-control" value={this.state.editor3_html} /> : ""}
                            </div>
                          </div>
                        </div>
						
						 <div className="col-md-12">
                          <div className="form-group row">
                            {this.state.editor4 && this.state.editor_type == "classic" ? <label className="col-sm-3 col-form-label">{this.state.editor4}</label> : ""}
                            <div className="col-sm-9" style={{ textAlign: "left" }}>
                              {this.state.editor4 ? <SunEditor setContents={this.state.editor4_value} showToolbar={true} height="300px" enableToolbar={true} setOptions={{
                                font: [
                                  'Arial',
                                  'Helvetica',
                                  'Times New Roman',
                                  'Verdana',
                                  'Courier New',
                                ],
                                buttonList: [
                                  ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                  ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                  ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print']
                                ]
                              }}
                                onChange={this.onChangeEditor4}
                              /> : ""}
                              {this.state.editor4 && this.state.editor_type == "html" ? <textarea type="text" style={{ minHeight: "300px" }} onChange={this.onChange} name="editor4_html" className="form-control" value={this.state.editor4_html} /> : ""}
                            </div>
                          </div>
                        </div>
						

                        <div className="col-md-12">
                          <div className="form-group row">
                            {this.state.select1 ? <label className="col-sm-3 col-form-label">{this.state.select1}</label> : ""}
                            <div className="col-sm-9">
                              {this.state.select1 ? <select value={this.state.select1_value} name="select1_value" onChange={this.onChange}>    <option value=""></option> {Select1} </select> : ""}
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12">
                          <div className="form-group row">
                            {this.state.select2 ? <label className="col-sm-3 col-form-label">{this.state.select2}</label> : ""}
                            <div className="col-sm-9">
                              {this.state.select2 ? <select value={this.state.select2_value} name="select2_value" onChange={this.onChange}>  <option value=""></option>{Select2} </select> : ""}
                            </div>
                          </div>
                        </div>
						
						
                        <div className="col-md-12">
                          <div className="form-group row">
                            {this.state.select3 ? <label className="col-sm-3 col-form-label">{this.state.select3}</label> : ""}
                            <div className="col-sm-9">
                              {this.state.select3 ? <select value={this.state.select3_value} name="select3_value" onChange={this.onChange}>  <option value=""></option>{Select3} </select> : ""}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group row">
                            {this.state.checkbox1 ? <label className="col-sm-3 col-form-label">{this.state.checkbox1}</label> : ""}
                            <div className="col-sm-9">
                              {this.state.checkbox1 ? <input name="checkbox1_value" type="checkbox" checked={this.state.checkbox1_value} onChange={this.onChangeCheckbox1} /> : ""}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group row">
                            {this.state.image1 ? <label className="col-sm-3 col-form-label">{this.state.image1}</label> : ""}
                            <div className="col-sm-9">
                              {this.state.image1 ? <input type="file" onChange={this.onChangeImage1} /> : ""}
                              <br /><br />
                              {this.state.image1_error ? this.state.image1_error : ""}
                              {this.state.image1_upload && this.state.image1_name ? <div> <img src={"https://api.typuje.pl/img/" + this.state.image1_folder + "/" + this.state.image1_name} style={{ maxHeight: "100px" }} /> <br /> {this.state.image1_name} </div> : ""}

                            </div>
                          </div>
                        </div>

                      </div>
                    </form>

                    <button onClick={(e) => { this.saveData() }}>Zapisz</button>
					<center>	{this.state.error} </center>
                  </div>
                </div>
              </div>   </div>   </div>   </div>

      </Layout>
    );

  }


}