import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom';
import All from './pages/All';
import Edit from './pages/Edit';
import Login from './pages/Login';


class App extends Component {

  render() {
    return (
      <Router>
        <div className="App">

          <Switch>
          
          <Route exact path="/"> <All controller={"content"} /> </Route>
          <Route exact path="/login"> <Login info={"login"} /> </Route>
          

            <Route exact path="/content/all"> <All controller={"content"} /> </Route>
            <Route exact path="/content/edit/:id" render = {props => <Edit {...props} controller={"content"} type={"edit"} /> } />
            <Route exact path="/content/add" render = {props => <Edit {...props} controller={"content"} type={"add"} /> } />
          
            <Route exact path="/cat/all"> <All controller={"cat"} /> </Route>
            <Route exact path="/cat/edit/:id" render = {props => <Edit {...props} controller={"cat"} type={"edit"} /> } />
            <Route exact path="/cat/add" render = {props => <Edit {...props} controller={"cat"} type={"add"} /> } />
         
            <Route exact path="/bookcontent/all"> <All controller={"bookcontent"} type={"add"} /> </Route>
            <Route exact path="/bookcontent/edit/:id" render = {props => <Edit {...props} controller={"bookcontent"} type={"edit"} /> } />
            <Route exact path="/bookcontent/add" render = {props => <Edit {...props} controller={"bookcontent"} type={"add"} /> } />
         
            <Route exact path="/promocode/all"> <All controller={"promocode"} type={"add"} /> </Route>
            <Route exact path="/promocode/edit/:id" render = {props => <Edit {...props} controller={"promocode"} type={"edit"} /> } />
            <Route exact path="/promocode/add" render = {props => <Edit {...props} controller={"promocode"} type={"add"} /> } />
         
            <Route exact path="/event/all"> <All controller={"event"} type={"add"} /> </Route>
            <Route exact path="/event/edit/:id" render = {props => <Edit {...props} controller={"event"} type={"edit"} /> } />
            <Route exact path="/event/add" render = {props => <Edit {...props} controller={"event"} type={"add"} /> } />
         
		 
		    <Route exact path="/bonus/all"> <All controller={"bonus"} type={"add"} /> </Route>
            <Route exact path="/bonus/edit/:id" render = {props => <Edit {...props} controller={"bonus"} type={"edit"} /> } />
            <Route exact path="/bonus/add" render = {props => <Edit {...props} controller={"bonus"} type={"add"} /> } />
         	  
			<Route exact path="/bookmaker/all"> <All controller={"bookmaker"} type={"add"} /> </Route>
            <Route exact path="/bookmaker/edit/:id" render = {props => <Edit {...props} controller={"bookmaker"} type={"edit"} /> } />
            <Route exact path="/bookmaker/add" render = {props => <Edit {...props} controller={"bookmaker"} type={"add"} /> } />
         
			<Route exact path="/sport/all"> <All controller={"sport"} type={"add"} /> </Route>
            <Route exact path="/sport/edit/:id" render = {props => <Edit {...props} controller={"sport"} type={"edit"} /> } />
            <Route exact path="/sport/add" render = {props => <Edit {...props} controller={"sport"} type={"add"} /> } />
         
		 	<Route exact path="/slider/all"> <All controller={"slider"} type={"add"} /> </Route>
            <Route exact path="/slider/edit/:id" render = {props => <Edit {...props} controller={"slider"} type={"edit"} /> } />
            <Route exact path="/slider/add" render = {props => <Edit {...props} controller={"slider"} type={"add"} /> } />
         
		 	<Route exact path="/static/all"> <All controller={"static"} type={"add"} /> </Route>
            <Route exact path="/static/edit/:id" render = {props => <Edit {...props} controller={"static"} type={"edit"} /> } />
            <Route exact path="/static/add" render = {props => <Edit {...props} controller={"static"} type={"add"} /> } />
         
		 
		        
		 	<Route exact path="/contentcat/all"> <All controller={"contentcat"} type={"add"} /> </Route>
            <Route exact path="/contentcat/edit/:id" render = {props => <Edit {...props} controller={"contentcat"} type={"edit"} /> } />
            <Route exact path="/contentcat/add" render = {props => <Edit {...props} controller={"contentcat"} type={"add"} /> } />
         
		  
		        
		 	<Route exact path="/competition/all"> <All controller={"competition"} type={"add"} /> </Route>
            <Route exact path="/competition/edit/:id" render = {props => <Edit {...props} controller={"competition"} type={"edit"} /> } />
            <Route exact path="/competition/add" render = {props => <Edit {...props} controller={"competition"} type={"add"} /> } />
         	 	
			<Route exact path="/got/all"> <All controller={"got"} type={"add"} /> </Route>
            <Route exact path="/got/edit/:id" render = {props => <Edit {...props} controller={"got"} type={"edit"} /> } />
            <Route exact path="/got/add" render = {props => <Edit {...props} controller={"got"} type={"add"} /> } />
         
    	 	
			<Route exact path="/user/all"> <All controller={"user"} type={"add"} /> </Route>
            <Route exact path="/user/edit/:id" render = {props => <Edit {...props} controller={"user"} type={"edit"} /> } />
            <Route exact path="/user/add" render = {props => <Edit {...props} controller={"user"} type={"add"} /> } />
         



          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;