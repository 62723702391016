import React, { Component } from "react";
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import Layout from "../compontents/layout";



class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      error: "",
      token: localStorage.getItem('token')
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleLogIn = this.handleLogIn.bind(this);
  }


  handleEmailChange(event) { this.setState({ email: event.target.value }); }
  handlePasswordChange(event) { this.setState({ password: event.target.value }); }



  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }



  handleLogIn() {
    axios.post('https://api.typuje.pl/api/login',
      { email: this.state.email, password: this.state.password })
      .then(response => {
        if (response.data) {
          console.log(response.data);
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('name', response.data.name);
          localStorage.setItem('email', response.data.email);
          localStorage.setItem('role', response.data.role);
          this.setState({
            loginStatus: true
          });
        }
      })
      .catch(function (error) {
        this.setState({
          error: 'Błąd! Złe dane logowania!'
        });
      }.bind(this));
  }



  render() {
    return (
      <Layout>
        <div className="main-panel">
          {this.state.loginStatus ? <Redirect to="/" /> : ""}
          <div className="content-wrapper">
            <div className="row page-title-header">
              <div className="col-12">
                <div className="page-header">
                </div>
                <div className="page-header">
                </div>

                <div className="page-header">
                </div>
              </div>


              {this.state.token ?  <div className="col-12 grid-margin"> Zalogowany pomyślnie. </div> :
                <div className="col-12 grid-margin">
                  <h3><center>Musisz się zalogować lub zarejestrować aby uzyskać dostęp do tego zasobu.</center>   </h3> <br />
                    <div className="form-group">
                      <label className="label">Username</label>
                      <div className="input-group">
                        <input type="text" className="form-control" value={this.state.email} onChange={this.handleEmailChange} />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="label">Password</label>
                      <div className="input-group">
                        <input type="password" class="form-control" value={this.state.password} onChange={this.handlePasswordChange} />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <button onClick={(e) => { this.handleLogIn() }} className="btn btn-primary submit-btn btn-block">Login</button>
                      {this.state.error}
                    </div>
                </div>}



            </div>  </div>  </div>
      </Layout>
    );
  }
}

export default Login;