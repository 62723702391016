import React from 'react';
import { Link } from 'react-router-dom';

export default function StartArea() {
     return (
         
          <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
              <li className="nav-item nav-profile">
              </li>
              <li className="nav-item nav-category">Main Menu</li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  <i className="menu-icon typcn typcn-document-text"></i>
                  <span className="menu-title">Dashboard</span>
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="collapse" aria-expanded="true" aria-controls="ui-basic">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Newsy</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse show" id="ui-basic">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                    <Link className="nav-link" to="/content/all">Wszystkie</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/content/add">Dodaj</Link>
                    </li>
                  </ul>
                </div>
              </li>


              <li className="nav-item">
                <a className="nav-link" data-toggle="collapse" aria-expanded="true" aria-controls="ui-basic">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Kategorie</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse show" id="ui-basic">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                    <Link className="nav-link" to="/cat/all">Wszystkie</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/cat/add">Dodaj</Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <a className="nav-link" data-toggle="collapse" aria-expanded="true" aria-controls="ui-basic">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Opisy bukmacherow</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse show" id="ui-basic">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                    <Link className="nav-link" to="/bookcontent/all">Wszystkie</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/bookcontent/add">Dodaj</Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <a className="nav-link" data-toggle="collapse" aria-expanded="true" aria-controls="ui-basic">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Kody promocyjne</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse show" id="ui-basic">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                    <Link className="nav-link" to="/promocode/all">Wszystkie</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/promocode/add">Dodaj</Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <a className="nav-link" data-toggle="collapse" aria-expanded="true" aria-controls="ui-basic">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Wydarzenia</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse show" id="ui-basic">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                    <Link className="nav-link" to="/event/all">Wszystkie</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/event/add">Dodaj</Link>
                    </li>
                  </ul>
                </div>
              </li>
			  
			  <li className="nav-item">
                <a className="nav-link" data-toggle="collapse" aria-expanded="true" aria-controls="ui-basic">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Bonusy</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse show" id="ui-basic">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                    <Link className="nav-link" to="/bonus/all">Wszystkie</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/bonus/add">Dodaj</Link>
                    </li>
                  </ul>
                </div>
              </li>
			  
			    <li className="nav-item">
                <a className="nav-link" data-toggle="collapse" aria-expanded="true" aria-controls="ui-basic">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Bukmacherzy</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse show" id="ui-basic">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                    <Link className="nav-link" to="/bookmaker/all">Wszystkie</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/bookmaker/add">Dodaj</Link>
                    </li>
                  </ul>
                </div>
              </li>
			  
			<li className="nav-item">
                <a className="nav-link" data-toggle="collapse" aria-expanded="true" aria-controls="ui-basic">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Sport</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse show" id="ui-basic">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                    <Link className="nav-link" to="/sport/all">Wszystkie</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/sport/add">Dodaj</Link>
                    </li>
                  </ul>
                </div>
              </li>
       
       	<li className="nav-item">
                <a className="nav-link" data-toggle="collapse" aria-expanded="true" aria-controls="ui-basic">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Slider</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse show" id="ui-basic">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                    <Link className="nav-link" to="/slider/all">Wszystkie</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/slider/add">Dodaj</Link>
                    </li>
                  </ul>
                </div>
              </li>
			  
			        	<li className="nav-item">
                <a className="nav-link" data-toggle="collapse" aria-expanded="true" aria-controls="ui-basic">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Statyczne</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse show" id="ui-basic">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                    <Link className="nav-link" to="/static/all">Wszystkie</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/static/add">Dodaj</Link>
                    </li>
                  </ul>
                </div>
              </li>
			  
			  			        	<li className="nav-item">
                <a className="nav-link" data-toggle="collapse" aria-expanded="true" aria-controls="ui-basic">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Kategorie newsow</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse show" id="ui-basic">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                    <Link className="nav-link" to="/contentcat/all">Wszystkie</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/contentcat/add">Dodaj</Link>
                    </li>
                  </ul>
                </div>
              </li>
			  
			  	  
			  			        	<li className="nav-item">
                <a className="nav-link" data-toggle="collapse" aria-expanded="true" aria-controls="ui-basic">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Konkursy</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse show" id="ui-basic">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                    <Link className="nav-link" to="/competition/all">Wszystkie</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/competition/add">Dodaj</Link>
                    </li>
                  </ul>
                </div>
              </li>
			  
			  	<li className="nav-item">
                <a className="nav-link" data-toggle="collapse" aria-expanded="true" aria-controls="ui-basic">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Typy Zakładów (SEO)</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse show" id="ui-basic">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                    <Link className="nav-link" to="/got/all">Wszystkie</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/got/add">Dodaj</Link>
                    </li>
                  </ul>
                </div>
              </li>
       
	   	  	<li className="nav-item">
                <a className="nav-link" data-toggle="collapse" aria-expanded="true" aria-controls="ui-basic">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Userzy</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse show" id="ui-basic">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                    <Link className="nav-link" to="/user/all">Wszystkie</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/user/add">Dodaj</Link>
                    </li>
                  </ul>
                </div>
              </li>
       
            
            </ul>
          </nav>


     );
}